import { Suspense, lazy } from 'react';
// import Event from '../Components/Event/Event';
import MainLayout from '../Components/Layout/MainLayout';
import SubLayoutMain from '../Components/Layout/SubLayoutMain';
// import Sessions from '../Components/Sessions/Sessions';
import Actors from '../Components/Actors/Actors';
import { QueryClient } from '@tanstack/react-query';
// import Slots from '../Components/Slots/Slots';
// import Event from '../Components/Event/Event';
import SessionOptions from '../Components/Sessions/Session/SessionOptions';
import PreviewLayout from '../Components/Layout/PreviewLayout';
import Preview from '../Components/Preview/Preview';
import { Box, Container, Skeleton, Stack, Toolbar } from '@mui/material';

const EventLazy = lazy(() => import('../Components/Event/Event'));
const SessionsLazy = lazy(() => import('../Components/Sessions/Sessions'));
const SlotsLazy = lazy(() => import('../Components/Slots/Slots'));
const Slots2Lazy = lazy(() => import('../Components/Slots2/Slots'));

const routesConfig = (queryClient: QueryClient) => [
  {
    element: <MainLayout menu="main" />,
    children: [
      {
        element: <SubLayoutMain menu="main" />,
        children: [
          {
            path: '/sessions',
            element: (
              <Suspense fallback={<SessionListDummy />}>
                <SessionsLazy />
              </Suspense>
            ),
          },
          {
            path: '/actors',
            element: <Actors />,
          },
          // {
          //   path: '/media',
          //   // lazy: async () => import('../Components/Sessions/Sessions'),
          //   element: <Media />,
          // },
        ],
      },
      {
        element: <SubLayoutMain menu="session" />,
        id: 'subMenu',
        children: [
          {
            path: '/sessions/:sessionId',
            element: <EventLazy />,
          },
          {
            path: '/sessions/:sessionId/slots',
            element: (
              <Suspense fallback={<SlotListDummy />}>
                <SlotsLazy />
                {/* {process.env.NODE_ENV === 'production' ? (
                  <SlotsLazy />
                ) : (
                  <Slots2Lazy />
                  // <SlotsLazy />
                )} */}
              </Suspense>
            ),
          },
          {
            path: '/sessions/:sessionId/actors',
            element: <Actors />,
          },
          {
            path: '/sessions/:sessionId/options',
            element: <SessionOptions />,
          },
        ],
      },
    ],
  },
  {
    element: <PreviewLayout />,
    children: [{ path: '/preview', element: <Preview /> }],
  },
  {
    path: '*',
    element: (() => {
      return <>Error</>;
    })(),
  },
];

export default routesConfig;

function SlotListDummy() {
  return (
    <>
      <Toolbar />
      <Container sx={{ height: 'calc(100% - 64px)' }}>
        <Stack direction="row" spacing={2} height="100%" p={4}>
          {/* <Box width="40%" height="100%"> */}
          <Skeleton variant="rounded" width="40%" height="100%" />
          {/* </Box> */}
          {/* <Box width="60%" height="100%"> */}
          <Skeleton variant="rounded" width="60%" height="100%" />
          {/* </Box> */}
        </Stack>
      </Container>
    </>
  );
}

function SessionListDummy() {
  return (
    <>
      <Toolbar />
      <Container sx={{ height: 'calc(100% - 64px)' }}>
        <Stack direction="column" spacing={2} height="100%" p={4}>
          {/* <Box width="40%" height="100%"> */}
          <Skeleton variant="rounded" width="100%" height="10%" />
          {/* </Box> */}
          {/* <Box width="60%" height="100%"> */}
          <Skeleton variant="rounded" width="100%" height="90%" />
          {/* </Box> */}
        </Stack>
      </Container>
    </>
  );
}
