import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

// export {};
type PreviewContextType = { view: string; setView: (view: string) => void };

const PreviewContext = createContext<PreviewContextType | null>(null);

export function PreviewContextProvider({ children }: { children: ReactNode }) {
  // view to preview
  const [view, setView] = useState('announcement');

  const value = { view: view, setView: setView };

  return (
    <PreviewContext.Provider value={value}>{children}</PreviewContext.Provider>
  );
}

export const usePreviewContext = (): PreviewContextType => {
  // use our context
  const context = useContext(PreviewContext);

  // make sure we return a valid context
  if (!context) {
    throw new Error('useSlotContext must be used within a SlotProvider');
  }

  return context;
};
