import {
  AppBar,
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { usePreviewContext } from '../Preview/PreviewContext';

export default function PreviewNavigation() {
  //
  const theme = useTheme();

  //
  const [iframeSrc, setIframeSrc] = useState();
  console.log('iframeSrc', iframeSrc);

  // preview screen
  //   const [previewScreen, setPreviewScreen] = useState('announcement');
  const { view, setView } = usePreviewContext();

  // reload fct
  const reloadPreview = () => {
    const iframeNode: any = document.getElementById('bs_PREVIEW_PLAYER');
    iframeNode.src = iframeNode.src;
  };

  useEffect(() => {
    const iframeNode: any = document.getElementById('bs_PREVIEW_PLAYER');
    if (iframeNode) setIframeSrc(iframeNode.src.split('&')[0]);
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 'none',
          zIndex: (theme) => {
            return theme.zIndex.drawer + 1;
          },
        }}
      >
        {/* <Toolbar /> */}
        {/* <Toolbar variant="dense" sx={{ px: { xs: 1, sm: 2 } }}> */}
        <Toolbar sx={{ px: { xs: 1, sm: 1 } }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  ml: { xs: 0, sm: '18px' },
                }}
                variant="logo"
              >
                Britestage
              </Typography>
              <Typography variant="body1">
                {' - Player Preview' +
                  (iframeSrc ? ' (' + iframeSrc + ')' : '')}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Select
                value={view}
                size="small"
                onChange={(e) => setView(e.target.value)}
                variant="outlined"
              >
                {[
                  <MenuItem key="0" value={'announcement'}>
                    Announcement
                  </MenuItem>,
                  <MenuItem key="1" value={'registration'}>
                    Registration
                  </MenuItem>,
                  <MenuItem key="2" value={'confirmation'}>
                    Bestätigung
                  </MenuItem>,
                  <MenuItem key="3" value={'welcome'}>
                    Willkommen
                  </MenuItem>,
                  <MenuItem key="4" value={'lobby'}>
                    Lobby
                  </MenuItem>,
                  <MenuItem key="5" value={'live'}>
                    Live
                  </MenuItem>,
                  <MenuItem key="6" value={'closed'}>
                    Verabschiedung
                  </MenuItem>,
                ]}
              </Select>

              <IconButton onClick={reloadPreview}>
                <CachedOutlinedIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
}
