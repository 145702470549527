import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export type updateEventApiParamsTypes = {
  eventId: string;
  basicData: any;
  metaData?: any;
  customizationData?: any;
  monetData?: any;
  registrationData?: any;
  onlineData?: any;
};

export default async function updateEventApi(data: updateEventApiParamsTypes) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;
  console.log('updateCustomizationData-3', data);

  // set correct enddate
  let start = null;
  let end = null;

  if (data.basicData) {
    // remove parse objects
    delete data.basicData.player;

    // set clean dates
    if (data?.basicData?.startdate) {
      start = new Date(data.basicData.startdate);
      start.setHours(9, 0, 0, 0);

      end = new Date(start);
      end.setDate(end.getDate() + data.basicData.duration);
      end.setHours(1, 0, 0, 0);
      end.setMilliseconds(end.getMilliseconds() - 50);

      data.basicData.startdate = start;
      data.basicData.enddate = end;
    }
  }

  if (data.registrationData) {
    // set clean times
    data.registrationData.data.registrationStarts?.setSeconds(0);
    data.registrationData.data.registrationStarts?.setMilliseconds(0);
  }

  if (data.onlineData) {
    // set clean times
    data.onlineData.data.starttimeLobby?.setSeconds(0);
    data.onlineData.data.starttimeLobby?.setMilliseconds(0);
  }

  return Parse.Cloud.run('updateEvent', data, { sessionToken: sessionToken });
}
