import { QueryStatus } from '@tanstack/react-query';
import { create } from 'zustand';

type State = {
  [key: string]: any;
};

// init empty store
const useLiveStore = create<State>((set) => ({
  dataSessions: null,
  dataSlots: null,
  status: 'pending',
  setDataSessions: (data: any) => set({ dataSessions: data }), // !!! typing
  setDataSlots: (data: any) => set({ dataSlots: data }), // !!! typing
  setStatus: (status: QueryStatus) => set({ status }),
  isSuccess: (state: State) => state.status === 'success',
  isError: (state: State) => state.status === 'error',
  isPending: (state: State) => state.status === 'pending',
}));

// // populate store
// export function populateAuthStore(data: any) {
//   useEventStore.setState(data);
// }

// export hook
export default useLiveStore;
