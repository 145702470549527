import { Outlet } from 'react-router-dom';
import SubNavigationMain, {
  SubNavigationParamsTypes,
} from '../Navigation/SubNavigationMain';
import routesConfig from '../../Router/routesConfig';
import { Box, Container, Skeleton, Stack, Toolbar } from '@mui/material';
import useEventStore from '../../store/eventStore';
import { useEffect, useState } from 'react';

export default function SubLayoutMain({ menu }: SubNavigationParamsTypes) {
  // global state data
  const { data: eventData, status: eventDataStatus } = useEventStore();

  const [displayContent, setDisplayContent] = useState(false);

  //// side effects
  useEffect(() => {
    // make sure session list is not displayed for singleSession Events
    // !!! not nice should be handled earlier
    if (
      eventData.get('multiSession') ||
      (!eventData.get('multiSession') && menu === 'session')
    ) {
      setDisplayContent(true);
    } else {
      setDisplayContent(false);
    }
  }, [menu, eventData]);

  return (
    <>
      {
        displayContent && (
          <>
            <SubNavigationMain menu={menu} />
            {/* <SlotListDummy /> */}
            <Outlet />
          </>
        )
        // : (
        //   <>
        //     {menu === 'main' && eventData.get('multiSession') && (
        //       <SessionListDummy />
        //     )}
        //     {menu === 'session' && !eventData.get('multiSession') && (
        //       <SlotListDummy />
        //     )}
        //   </>
        // )
      }
    </>
  );
}
