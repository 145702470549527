import * as yup from 'yup';
import i18n from 'i18next';

export const eventDetailsFeatureRegistrationValidationSchema = yup.object({
  // useRegistration: yup.boolean(),
  embed: yup.boolean(),
  registrationUrl: yup.string(),
  registrationStarts: yup.date(),
  confirmEmail: yup.boolean(),
  registrationText: yup.string(),
  showEventText: yup.boolean(),
  showAgenda: yup.boolean(),
  showCountDown: yup.boolean(),
  showRegistrationText: yup.boolean(),
  fieldDefinition: yup.object(),
  confirmationText: yup.string(),
  showEventTextConfirmation: yup.boolean(),
  showAgendaConfirmation: yup.boolean(),
  showCountDownConfirmation: yup.boolean(),
  showConfirmationTextConfirmation: yup.boolean(),
  welcomeText: yup.string(),
  showEventTextWelcome: yup.boolean(),
  showAgendaWelcome: yup.boolean(),
  showCountDownWelcome: yup.boolean(),
  showWelcomeTextWelcome: yup.boolean(),
});

export const eventDetailsFeatureRegistrationDefaults = {
  // useRegistration: false,
  embed: false,
  registrationUrl: '',
  registrationStarts: new Date(),
  confirmEmail: false,
  registrationText: '',
  showEventText: false,
  showAgenda: false,
  showCountDown: false,
  showRegistrationText: true,
  fieldDefinition: {
    address: { active: false, required: false },
    organisation: { active: false, required: false },
    phone: { active: false, required: false },
    certificate: { active: false, required: false },
    memberId: { active: false, required: false },
  },
  confirmationText: '',
  showEventTextConfirmation: false,
  showAgendaConfirmation: false,
  showCountDownConfirmation: false,
  showConfirmationTextConfirmation: true,
  welcomeText: '',
  showEventTextWelcome: false,
  showAgendaWelcome: false,
  showCountDownWelcome: false,
  showWelcomeTextWelcome: true,
};
