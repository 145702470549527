import { Container } from '@mui/material';
import StaffList from '../../Staff/StaffList';

export default function SessionOptions() {
  return (
    <Container sx={{ px: { xs: 1, sm: 2 }, mt: { xs: '40px', sm: '64px' } }}>
      <StaffList role="sessionManager" />
    </Container>
  );
}
