import { Box, Toolbar } from '@mui/material';
import PreviewNavigation from '../Navigation/PreviewNavigation';
import { Outlet, useParams } from 'react-router-dom';
import { PreviewContextProvider } from '../Preview/PreviewContext';

export default function PreviewLayout() {
  return (
    <>
      <PreviewContextProvider>
        <PreviewNavigation />

        {/* Spacer */}
        <Toolbar></Toolbar>
        {/* <Toolbar variant="dense"></Toolbar> */}
        <Outlet />
      </PreviewContextProvider>
    </>
  );
}
