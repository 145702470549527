export function displayEventFromToDate(startdate: Date, enddate: Date) {
  // get midnight of start day
  const startDateMidnight = new Date(startdate);
  startDateMidnight.setMilliseconds(999);
  startDateMidnight.setSeconds(59);
  startDateMidnight.setMinutes(59);
  startDateMidnight.setHours(23);

  // set midnight to hour desired endpoint (1:00 next day)
  startDateMidnight.setHours(
    startDateMidnight.getHours() + 1,
    startDateMidnight.getMinutes() + 1,
    0,
    0,
  );

  let dateString = startdate.toLocaleDateString();

  const endDate = new Date(enddate);

  // if (enddate > startDateMidnight) {
  if (startDateMidnight.getTime() < endDate.getTime()) {
    dateString +=
      ' - ' +
      new Date(endDate.setDate(endDate.getDate() - 1)).toLocaleDateString();
  }

  return dateString;
}

export function getAllDates(eventTimes: any) {
  const dates = [];
  let currentDate = new Date(eventTimes.startdate);

  let index = 1;
  while (currentDate <= eventTimes.enddate) {
    // dates.push(new Date(currentDate));
    dates.push({
      label: 'Tag ' + index + ' (' + currentDate.toLocaleDateString() + ')',
      value: currentDate.toISOString(),
    });
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    index++;
  }

  return dates;
}
