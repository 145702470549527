import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Stack,
  Typography,
} from '@mui/material';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import {
  ErDatePicker,
  ErDateTimePicker,
  ErHtmlEditor,
  ErSelect,
  ErSwitch,
  ErTextField,
  ErTimePickerDesktop,
} from 'eventrocket-commoncomponents';
import {
  eventDetailsFeatureOnlineStageValidationSchema,
  eventDetailsFeatureOnlineStageDefaults,
} from './eventDetailsFeatureOnlineStageValidationSchema';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { htmlEditorDefaultConfig } from '../../../util/htmlEditor/htmlEditorDefaultConfig';

type EventDetailsFormLayoutFeaturesOnlineStagePropsTypes = {
  featureState?: boolean;
};
export default function EventDetailsFormLayoutFeaturesOnlineStage(
  props: EventDetailsFormLayoutFeaturesOnlineStagePropsTypes,
) {
  // props
  const { featureState = false } = props;

  // event details context
  const { onlineData, updateOnlineData, basicData } = useEventDetailsFormData();

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsFeatureOnlineStageValidationSchema),
    defaultValues: {
      ...eventDetailsFeatureOnlineStageDefaults,
      ...onlineData?.data,
    },
  });

  const watch = useWatch({ control });
  const watchEmbed = useWatch({ name: 'embed', control });

  // state
  const [embedActive, setEmbedActive] = useState(false);

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context
    if (!firstRun) {
      updateOnlineData(getValues(), featureState ? featureState : false);
    } else {
      setFirstRun(false);
    }
  }, [watch, featureState]);

  useEffect(() => {
    setEmbedActive(getValues('embed'));
  }, [watchEmbed]);

  return (
    <Stack sx={{ mt: 2 }}>
      <Accordion disabled={!featureState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="basic-options"
          id="basic-options-header"
        >
          Basic Settings
        </AccordionSummary>
        <AccordionDetails>
          {/* <Stack direction="row" alignItems="baseline">
        <Typography variant="body1">Einbinden</Typography>
        <ErSwitch
          disabled={!featureState}
          control={control}
          fieldName="embed"
          size="small"
          sx={{ mb: 1 }}
        />
      </Stack> */}

          {/* <Collapse in={embedActive}>
            <ErTextField
              disabled={!featureState}
              fullWidth
              size="small"
              control={control}
              fieldLabel="Event Url"
              fieldName="eventUrl"
              sx={{ mb: 2 }}
              error={errors.eventUrl && getErrorMessage(errors, 'eventUrl')}
            />
          </Collapse>

          <ErSelect
            disabled={!featureState}
            size="small"
            control={control}
            fieldLabel="Event Szene"
            fieldName="fallbackScene"
            menuItems={[]}
            sx={{ mb: 2 }}
          /> */}

          <ErTextField
            disabled={!featureState}
            fullWidth
            size="small"
            control={control}
            fieldLabel="Zugangs Code"
            fieldName="accessCode"
            sx={{ mb: 2 }}
            error={errors.accessCode && getErrorMessage(errors, 'accessCode')}
          />

          {/* <ErDatePicker
        disabled={!featureState}
        fullWidth
        control={control}
        fieldLabel="Öffnungszeit Lobby"
        fieldName="starttimeLobby"
        textFieldSize="small"
        error={
          errors.starttimeLobby && getErrorMessage(errors, 'starttimeLobby')
        }
        sx={{ mb: 2 }}
      /> */}

          {/* <ErDateTimePicker
        disabled={!featureState}
        fullWidth
        control={control}
        fieldLabel="Öffnungszeit Lobby"
        fieldName="starttimeLobby"
        textFieldSize="small"
        error={
          errors.starttimeLobby && getErrorMessage(errors, 'starttimeLobby')
        }
        sx={{ mb: 2 }}
      /> */}

          {basicData.data.multiSession && (
            <ErTimePickerDesktop
              disabled={!featureState}
              fullWidth
              control={control}
              fieldLabel="Öffnungszeit Lobby"
              fieldName="starttimeLobby"
              size="small"
              error={
                errors.starttimeLobby &&
                getErrorMessage(errors, 'starttimeLobby')
              }
              sx={{ mb: 2 }}
            />
          )}

          {/* <ErTextField
        disabled={!featureState}
        fullWidth
        size="small"
        control={control}
        fieldLabel="Player Text"
        fieldName="playerText"
        sx={{ mb: 2 }}
        error={errors.playerText && getErrorMessage(errors, 'playerText')}
      /> */}

          {/* <ErTextField
        disabled={!featureState}
        fullWidth
        size="small"
        control={control}
        fieldLabel="Verabschiedungs Text"
        fieldName="farewellText"
        sx={{ mb: 2 }}
        error={errors.farewellText && getErrorMessage(errors, 'farewellText')}
      /> */}
        </AccordionDetails>
      </Accordion>

      <Accordion disabled={!featureState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="basic-options"
          id="basic-options-header"
        >
          Lobby
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" spacing={2}>
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldLabel="Event Text anzeigen"
              labelPos="end"
              fieldName="showEventText"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldLabel="Agenda anzeigen"
              labelPos="end"
              fieldName="showAgenda"
              size="small"
              sx={{ mb: 2 }}
            />
          </Stack>

          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldLabel="Countdown anzeigen"
              labelPos="end"
              fieldName="showCountDown"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldLabel="Lobbytext anzeigen"
              labelPos="end"
              fieldName="showLobbyText"
              size="small"
              sx={{ mb: 2 }}
            />
          </Stack>

          <ErHtmlEditor
            fullWidth
            control={control}
            editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
            fieldLabel="Lobby Text"
            fieldName="playerText"
            sx={{ mb: 2 }}
            error={errors.playerText && getErrorMessage(errors, 'playerText')}
            onChangeCallback={() =>
              onlineData &&
              !onlineData?.isDirty &&
              updateOnlineData({}, onlineData.active, true)
            }
            language="de"
          />

          {/* <Stack direction="row" alignItems="baseline">
            <Typography variant="body1">Agenda anzeigen</Typography>
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldName="showAgenda"
              size="small"
              sx={{ mb: 2 }}
            />
          </Stack> */}

          {/* <Stack direction="row" alignItems="baseline">
            <Typography variant="body1">Event Text anzeigen</Typography>
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldName="showEventText"
              size="small"
              sx={{ mb: 2 }}
            />
          </Stack> */}
        </AccordionDetails>
      </Accordion>

      <Accordion disabled={!featureState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="basic-options"
          id="basic-options-header"
        >
          Verabschiedung
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" spacing={2}>
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldLabel="Event Text anzeigen"
              labelPos="end"
              fieldName="showEventTextFarewell"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldLabel="Agenda anzeigen"
              labelPos="end"
              fieldName="showAgendaFarewell"
              size="small"
              sx={{ mb: 2 }}
            />
          </Stack>

          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldLabel="Countdown anzeigen"
              labelPos="end"
              fieldName="showCountDownFarewell"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldLabel="Verabschiedungstext anzeigen"
              labelPos="end"
              fieldName="showFarewellText"
              size="small"
              sx={{ mb: 2 }}
            />
          </Stack>

          <ErHtmlEditor
            fullWidth
            control={control}
            editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
            fieldLabel="Verabschiedungs Text"
            fieldName="farewellText"
            sx={{ mb: 2 }}
            error={
              errors.farewellText && getErrorMessage(errors, 'farewellText')
            }
            onChangeCallback={() =>
              onlineData &&
              !onlineData?.isDirty &&
              updateOnlineData({}, onlineData.active, true)
            }
            language="de"
          />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
