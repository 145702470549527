import useAuthStore from '../../store/authStore';
import permissionConfig from '../../permission.config.json';
import { getUserRolesApi } from '../../api';

type hasPermissionParamTypes = {
  objPath: string;
  context?: {
    eventId?: string;
    sessionId?: string;
    slotId?: string;
    userId?: string;
  };
  mm?: 'min' | 'max'; // Standardwert 'min'
  operation?: 'create' | 'read' | 'update' | 'delete'; // Standardwert 'read'
};

export default function useUserPermissions() {
  // user roles
  const userRoles: string[] = useAuthStore.getState().userRoles;

  // user
  const user: any = useAuthStore.getState().user;

  const hasPermission = (params: hasPermissionParamTypes) => {
    //

    const { objPath, mm = 'min', operation = 'read', context } = params;

    const configRole: { [key: string]: any } = getPropertyByPath(objPath);
    let requiredRol: string | string[] = configRole[mm!][operation!];

    if (requiredRol) {
      if (typeof requiredRol === 'string') requiredRol = [requiredRol];

      for (const cr of requiredRol) {
        const check = checkPermission(
          cr,
          mm,
          operation,
          context,
          userRoles,
          user,
        );
        if (check) return true;
      }

      return false;
    } else {
      console.error('No permission definition found for provided path.');
    }
  };

  const getUserRoles = async (eventId: string) => {
    const userRoles = await getUserRolesApi(eventId);
    useAuthStore.setState({ userRoles: userRoles });
  };

  return { hasPermission, getUserRoles };
}

function checkPermission(
  requiredRole: string,
  mm: any,
  operation: any,
  context: any,
  userRoles: any,
  user: any,
) {
  if (context) {
    // test respecting context
    // const requiredRole: any = configRole[mm!][operation!];

    let objectId: string;

    switch (requiredRole) {
      case 'eventAdmin':
      case 'eventManager':
      case 'defaultUser':
        objectId = context.eventId!;
        break;
      case 'sessionManager':
      case 'sessionRead':
        objectId = context.sessionId!;
        break;
      case 'slotGroup':
        objectId = context.slotId!;
        break;
      default:
        objectId = context.userId!;
    }

    if (requiredRole !== 'owner') {
      const roleWithId = requiredRole + '_' + objectId;

      return userRoles.includes(roleWithId);
    } else {
      // user specific accessright

      return objectId === user.objectId;
    }
  } else {
    // test general role

    return userRoles.some((role: any) => role.includes(requiredRole));
  }
}

function getPropertyByPath(path: string) {
  const properties = path.split('.');

  // Navigieren Sie durch das Objekt entlang des Pfades
  // !!! typing
  let current: any = permissionConfig;
  for (const prop of properties) {
    if (current[prop] === undefined) {
      return undefined; // oder werfen Sie einen Fehler, wenn das bevorzugt wird
    }
    current = current[prop];
  }

  return current;
}
