import { useEffect, useState } from 'react';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import useEventDetailsFormDataHandling from './useEventDetailsFormDataHandling';
import EventDetailsFormLayoutCustomization from './EventDetailsFormLayoutCustomization';
import EventDetailsFormLayoutFeatures from './EventDetailsFormLayoutFeatures';
import { useTranslation } from 'react-i18next';
import EventDetailsFormLayoutGeneral from './EventDetailsFormLayoutGeneral';
import EventDetailsFormLayoutAccess from './EventDetailsFormLayoutAccess';
import EventDetailsFormSectionMenu from './EventDetailsFormSectionMenu';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import StyleIcon from '@mui/icons-material/Style';
import EventDetailsFormLayoutMarketing from './EventDetailsFormLayoutMarketing';

export default function EventDetailsFormLayout() {
  // props

  //// state
  // active form
  const [activeForm, setActiveForm] = useState('basics');
  // toggle the display of the form container
  const [displayForm, setDisplayForm] = useState(false);

  // theme media queries
  const theme = useTheme();

  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // translation
  const { t, i18n } = useTranslation('event');

  const formHeadline: { [key: string]: string } = {
    main: 'Einstellungen',
    general: '',
    basics: t(
      'DETAILS-FORM.SECTION.BASIC.HEADLINE',
      '_DETAILS-FORM.SECTION.BASIC.HEADLINE',
    ),
    meta: t(
      'DETAILS-FORM.SECTION.META.HEADLINE',
      '_DETAILS-FORM.SECTION.META.HEADLINE',
    ),
    access: t(
      'DETAILS-FORM.SECTION.ACCESS.HEADLINE',
      '_DETAILS-FORM.SECTION.ACCESS.HEADLINE',
    ),
    customization: '',
    branding: t(
      'DETAILS-FORM.SECTION.BRANDING.HEADLINE',
      '_DETAILS-FORM.SECTION.BRANDING.HEADLINE',
    ),
    legal: t(
      'DETAILS-FORM.SECTION.LEGAL.HEADLINE',
      '_DETAILS-FORM.SECTION.LEGAL.HEADLINE',
    ),
    onlineStageDisplay: t(
      'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.HEADLINE',
      '_DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.HEADLINE',
    ),
    features: '',
    mediaMng: t(
      'DETAILS-FORM.SECTION.MEDIAMNG.HEADLINE',
      '_DETAILS-FORM.SECTION.MEDIAMNG.HEADLINE',
    ),
    monet: t(
      'DETAILS-FORM.SECTION.MONET.HEADLINE',
      '_DETAILS-FORM.SECTION.MONET.HEADLINE',
    ),
    register: t(
      'DETAILS-FORM.SECTION.REGISTRATION.HEADLINE',
      '_DETAILS-FORM.SECTION.REGISTRATION.HEADLINE',
    ),
    online: t(
      'DETAILS-FORM.SECTION.ONLINE.HEADLINE',
      '_DETAILS-FORM.SECTION.ONLINE.HEADLINE',
    ),
  };

  // form data
  const { eventId, basicData, monetData, eventSettingsIsDirty, handleCancel } =
    useEventDetailsFormData();

  const { handleSubmit } = useEventDetailsFormDataHandling();

  //// functions
  // menu action handler
  const changeMenu = async (menuId: string) => {
    setActiveForm(menuId);
    setDisplayForm(true);
  };

  // manually blur input fields
  // mainly needed for jodit editor data submit behaviour
  const handleSubmitButtonMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    // Get the active element, which should be the input field
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement && activeElement.tagName === 'INPUT') {
      activeElement.blur();
    }
  };

  //// side effects
  useEffect(() => {
    // if screensize changed to mobile reset the menu open flag
    if (mediaQuery) {
      setDisplayForm(false);
    }
  }, [mediaQuery]);

  return (
    <>
      <Stack
        direction="row"
        sx={{
          width: { xs: '200vw', sm: '100%' },
          height: { xs: '100%', sm: '80vh' },
          maxHeight: { xs: '100vh', sm: '480px' },
          position: { xs: 'absolute', sm: 'unset' },
          transform:
            mediaQuery && displayForm ? 'translateX(-100vw)' : 'translateX(0)',
          transition: theme.transitions.create(['transform', 'transform'], {
            duration: theme.transitions.duration.standard,
          }),
        }}
      >
        {/* Menu */}
        <Box
          sx={{
            width: { xs: '100vw', sm: '25%' },
            // px: '10px',
            flex: { xs: '0 0 100vw', sm: '0 0 25%' },
          }}
        >
          {/* Header */}
          <Box
            display="flex"
            width="100%"
            height={mediaQuery ? '44px' : '60px'}
            alignItems="center"
          >
            {/* Desktop */}
            {!mediaQuery && (
              <Typography variant="h6" marginLeft={2}>
                {t('DETAILS-FORM.HEADLINE', '_DETAILS-FORM.HEADLINE')}
              </Typography>
            )}

            {/* Mobile */}
            {mediaQuery && (
              <Stack
                sx={{ width: '100%', pr: '5px', height: '100%' }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                bgcolor={theme.palette.navigationBg.main}
              >
                <Stack direction="row" alignItems="center">
                  <IconButton
                    size="large"
                    onClick={handleCancel}
                    sx={{ p: 0, width: '40px', height: '40px' }}
                    color="primary"
                  >
                    <KeyboardArrowLeftOutlinedIcon
                      sx={{ width: '40px', height: '40px' }}
                    />
                  </IconButton>
                  <Stack>
                    <Typography variant="InfoTextBold">
                      {basicData.data.title}
                    </Typography>
                    <Typography variant="InfoText">
                      {t('DETAILS-FORM.HEADLINE', '_DETAILS-FORM.HEADLINE')}
                    </Typography>
                  </Stack>
                </Stack>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!eventSettingsIsDirty}
                >
                  {t('DETAILS-FORM.SAVE_button', '_DETAILS-FORM.SAVE_button')}
                </Button>
              </Stack>
            )}
          </Box>

          {/* Buttons */}
          <Stack
            sx={{ px: 1, py: 1.5 }}
            bgcolor={theme.palette.background.default}
          >
            {/* GENERAL */}
            <Box>
              {mediaQuery && (
                <>
                  <Typography variant="subtitle1" color="primary" padding={1}>
                    {t(
                      'DETAILS-FORM.SECTION.BASIC.HEADLINE',
                      '_DETAILS-FORM.SECTION.BASIC.HEADLINE',
                    )}
                  </Typography>

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.BASIC.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.BASIC.SUBLINE'
                    }
                    Icon={SettingsOutlinedIcon}
                    clickHandler={() => changeMenu('basics')}
                  />

                  <Divider />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.META.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.META.SUBLINE'
                    }
                    Icon={InfoOutlinedIcon}
                    clickHandler={() => changeMenu('meta')}
                  />

                  <Divider />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.ACCESS.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.ACCESS.SUBLINE'
                    }
                    Icon={PeopleOutlineOutlinedIcon}
                    clickHandler={() => changeMenu('access')}
                  />
                </>
              )}

              {!mediaQuery && (
                <>
                  <Button
                    fullWidth
                    variant="text"
                    size="small"
                    sx={{
                      justifyContent: 'space-between',
                      color: theme.palette.text.primary,
                    }}
                    onClick={() => changeMenu('general')}
                  >
                    {t(
                      'DETAILS-FORM.MENU.GENERAL_button',
                      '_DETAILS-FORM.MENU.GENERAL_button',
                    )}
                  </Button>

                  <Button
                    fullWidth
                    variant="text"
                    size="small"
                    sx={{
                      justifyContent: 'space-between',
                      color: theme.palette.text.primary,
                    }}
                    onClick={() => changeMenu('access')}
                  >
                    {t(
                      'DETAILS-FORM.MENU.ACCESS_button',
                      '_DETAILS-FORM.MENU.ACCESS_button',
                    )}
                  </Button>
                </>
              )}
            </Box>

            {/* CUSTOMIZATION */}
            <Box sx={{ mt: mediaQuery ? 1 : 0 }}>
              {mediaQuery && (
                <>
                  <Typography variant="subtitle1" color="primary" padding={1}>
                    {t(
                      'DETAILS-FORM.SECTION.CUSTOMIZATION.HEADLINE',
                      '_DETAILS-FORM.SECTION.CUSTOMIZATION.HEADLINE',
                    )}
                  </Typography>

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.BRANDING.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.BRANDING.SUBLINE'
                    }
                    Icon={PaletteOutlinedIcon}
                    clickHandler={() => changeMenu('branding')}
                  />

                  <Divider />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.LEGAL.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.LEGAL.SUBLINE'
                    }
                    Icon={PolicyOutlinedIcon}
                    clickHandler={() => changeMenu('legal')}
                  />

                  <Divider />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SUBLINE'
                    }
                    Icon={StyleIcon}
                    clickHandler={() => changeMenu('onlineStageDisplay')}
                  />
                </>
              )}

              {!mediaQuery && (
                <Button
                  fullWidth
                  variant="text"
                  size="small"
                  sx={{
                    justifyContent: 'space-between',
                    color: theme.palette.text.primary,
                  }}
                  onClick={() => changeMenu('customization')}
                >
                  {t(
                    'DETAILS-FORM.MENU.CUSTOMIZATION_button',
                    '_DETAILS-FORM.MENU.CUSTOMIZATION_button',
                  )}
                </Button>
              )}
            </Box>

            {/* Marketing */}
            <Box sx={{ mt: mediaQuery ? 1 : 0 }}>
              {mediaQuery && (
                <>
                  <Typography variant="subtitle1" color="primary" padding={1}>
                    {t(
                      'DETAILS-FORM.SECTION.MARKETING.HEADLINE',
                      '_DETAILS-FORM.SECTION.MARKETING.HEADLINE',
                    )}
                  </Typography>

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.MARKETINGTEXT.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.MARKETINGTEXT.SUBLINE'
                    }
                    Icon={TextSnippetOutlinedIcon}
                    clickHandler={() => changeMenu('marketingText')}
                    // PaymentIcon={<CheckCircleOutlineOutlinedIcon />}
                  />

                  <Divider />
                </>
              )}

              {!mediaQuery && (
                <Button
                  fullWidth
                  variant="text"
                  size="small"
                  sx={{
                    justifyContent: 'space-between',
                    color: theme.palette.text.primary,
                  }}
                  endIcon={mediaQuery && <ArrowForwardIosIcon />}
                  onClick={() => changeMenu('marketing')}
                >
                  {t(
                    'DETAILS-FORM.MENU.MARKETING_button',
                    '_DETAILS-FORM.MENU.MARKETING_button',
                  )}
                </Button>
              )}
            </Box>

            {/* FEATURES */}
            <Box sx={{ mt: mediaQuery ? 1 : 0 }}>
              {mediaQuery && (
                <>
                  <Typography variant="subtitle1" color="primary" padding={1}>
                    {t(
                      'DETAILS-FORM.SECTION.FEATURES.HEADLINE',
                      '_DETAILS-FORM.SECTION.FEATURES.HEADLINE',
                    )}
                  </Typography>

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.MEDIAMNG.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.MEDIAMNG.SUBLINE'
                    }
                    Icon={StarsOutlinedIcon}
                    clickHandler={() => changeMenu('mediaMng')}
                    PaymentIcon={<CheckCircleOutlineOutlinedIcon />}
                  />

                  <Divider />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.MONET.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.MONET.SUBLINE'
                    }
                    Icon={MonetizationOnOutlinedIcon}
                    clickHandler={() => changeMenu('monet')}
                    PaymentIcon={<CheckCircleOutlineOutlinedIcon />}
                  />

                  <Divider />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.REGISTRATION.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.REGISTRATION.SUBLINE'
                    }
                    Icon={HowToRegOutlinedIcon}
                    clickHandler={() => changeMenu('register')}
                    PaymentIcon={<CheckCircleOutlineOutlinedIcon />}
                  />

                  <Divider />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.ONLINE.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.ONLINE.SUBLINE'
                    }
                    Icon={SmartDisplayOutlinedIcon}
                    clickHandler={() => changeMenu('online')}
                    PaymentIcon={<CheckCircleOutlineOutlinedIcon />}
                  />
                </>
              )}

              {!mediaQuery && (
                <Button
                  fullWidth
                  variant="text"
                  size="small"
                  sx={{
                    justifyContent: 'space-between',
                    color: theme.palette.text.primary,
                  }}
                  endIcon={mediaQuery && <ArrowForwardIosIcon />}
                  onClick={() => changeMenu('features')}
                >
                  {t(
                    'DETAILS-FORM.MENU.FEATURES_button',
                    '_DETAILS-FORM.MENU.FEATURES_button',
                  )}
                </Button>
              )}
            </Box>
          </Stack>
        </Box>

        {/* Content / forms */}
        <Box
          sx={{
            width: { xs: '100vw', sm: '75%' },
            // px: '10px',
            position: 'relative',
            flex: { xs: '0 0 100vw', sm: '0 0 75%' },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Header */}
          <Box
            display="flex"
            flexShrink={0}
            width="100%"
            height={mediaQuery ? '44px' : '60px'}
            alignItems="center"
            justifyContent="flex-end"
          >
            {!mediaQuery && (
              <IconButton
                size="small"
                onClick={handleCancel}
                sx={{ position: 'absolute', p: 0, top: '10px', right: '10px' }}
              >
                <CloseOutlinedIcon />
              </IconButton>
            )}

            {mediaQuery && (
              <Stack
                sx={{ width: '100%', pr: '5px', height: '100%' }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                bgcolor={theme.palette.navigationBg.main}
              >
                <Stack direction="row" alignItems="center">
                  <IconButton
                    size="large"
                    onClick={() => {
                      setDisplayForm(false);
                    }}
                    sx={{ p: 0, width: '40px', height: '40px' }}
                    color="primary"
                  >
                    <KeyboardArrowLeftOutlinedIcon
                      sx={{ width: '40px', height: '40px' }}
                    />
                  </IconButton>

                  <Typography variant="InfoTextBold">
                    {formHeadline[activeForm]}
                  </Typography>
                </Stack>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!eventSettingsIsDirty}
                >
                  {t('DETAILS-FORM.SAVE_button', '_DETAILS-FORM.SAVE_button')}
                </Button>
              </Stack>
            )}
          </Box>

          {/* Content */}
          <Box
            width="100%"
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              py: 1.5,
              px: 2,
            }}
          >
            {(activeForm === 'general' ||
              activeForm === 'basics' ||
              activeForm === 'meta') && (
              <EventDetailsFormLayoutGeneral
                mediaQueryMobile={mediaQuery}
                featureType={activeForm}
              />
            )}
            {/* {activeForm === 'access' && <StaffList role="eventManager" />} */}
            {activeForm === 'access' && (
              <EventDetailsFormLayoutAccess mediaQueryMobile={mediaQuery} />
            )}

            {(activeForm === 'customization' ||
              activeForm === 'branding' ||
              activeForm === 'legal' ||
              activeForm === 'onlineStageDisplay') && (
              <EventDetailsFormLayoutCustomization
                mediaQueryMobile={mediaQuery}
                featureType={activeForm}
              />
            )}

            {(activeForm === 'marketing' || activeForm === 'marketingText') && (
              <EventDetailsFormLayoutMarketing
                mediaQueryMobile={mediaQuery}
                featureType={activeForm}
              />
            )}

            {(activeForm === 'features' ||
              activeForm === 'mediaMng' ||
              activeForm === 'monet' ||
              activeForm === 'register' ||
              activeForm === 'online') && (
              <EventDetailsFormLayoutFeatures
                mediaQueryMobile={mediaQuery}
                featureType={activeForm}
              />
            )}
          </Box>

          {/* Desktop Footer */}
          {!mediaQuery && (
            <Box
              width="100%"
              display="flex"
              flexShrink={0}
              justifyContent="end"
              sx={{ mb: 2, pr: 1 }}
              height="60px"
              alignItems="center"
            >
              <Button
                size="small"
                variant="contained"
                onMouseDown={handleSubmitButtonMouseDown}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  handleSubmit();
                }}
                disabled={!eventSettingsIsDirty}
              >
                {t('DETAILS-FORM.SAVE_button', '_DETAILS-FORM.SAVE_button')}
              </Button>
            </Box>
          )}
        </Box>
      </Stack>
    </>
  );
}
