import * as yup from 'yup';
import i18n from 'i18next';

export const eventDetailsMarketingValidationSchema = yup.object({
  announcementHtml: yup.string(),
  showEventText: yup.boolean(),
  showAnnouncementText: yup.boolean(),
  showProgram: yup.boolean(),
  showCountDown: yup.boolean(),
  // agendaHtml: yup.string(),
});

export const eventDetailsMarketingDefaults = {
  announcementHtml: '',
  // agendaHtml: '',
  showEventText: true,
  showAnnouncementText: true,
  showProgram: true,
  showCountDown: false,
};
