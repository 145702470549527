import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import { useState } from 'react';
import EventDetailsFormLayoutFeaturesMonet from './EventDetailsFormLayoutFeaturesMonet';
import EventDetailsFormLayoutFeaturesOnlineStage from './EventDetailsFormLayoutFeaturesOnlineStage';
import EventDetailsFormLayoutFeaturesRegistration from './EventDetailsFormLayoutFeaturesRegistration';
import EventDetailsFormSection from './EventDetailsFormSection';

import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import EventDetailsFormLayoutFeaturesMedia from './EventDetailsFormLayoutFeaturesMedia';

type EventDetailsFormLayoutFeaturesPropsTypes = {
  mediaQueryMobile: boolean;
  featureType?: string;
};

export default function EventDetailsFormLayoutFeatures(
  props: EventDetailsFormLayoutFeaturesPropsTypes,
) {
  // props
  const { mediaQueryMobile, featureType } = props;

  // event details context
  const { basicData } = useEventDetailsFormData();
  const features = basicData.data.features_active;

  //// media management
  // state
  const mediaMngOrigState =
    features &&
    features.hasOwnProperty('mediaMng') &&
    features['mediaMng'].length > 0;

  console.log('#!#medMng-mediaMngOrigState', mediaMngOrigState);

  const [mediaMngActive, setMediaMngActive] = useState(mediaMngOrigState);
  console.log('#!#medMng-mediaMngActive', mediaMngActive);
  const toggleMediaMngActive = () => {
    // only change state if media management hasn't already paid members
    if (
      !mediaMngOrigState
      // ||  !features.mediaMng.some((item: any) => item.status === 'paid')
    ) {
      setMediaMngActive((mma: boolean) => !mma);
    }
  };

  //// monetiziation
  // state
  const monetOrigState =
    features && features.hasOwnProperty('monet') && features.monet.length > 0;
  const [monetActive, setMonetActive] = useState(monetOrigState);

  const toggleMonetActive = () => {
    setMonetActive((ma: boolean) => !ma);
  };

  //// registration
  // state
  const registrationOrigState =
    features &&
    features.hasOwnProperty('registration') &&
    features.registration.length > 0;
  const [registrationActive, setRegistrationActive] = useState(
    registrationOrigState,
  );

  const toggleRegistrationActive = () => {
    setRegistrationActive((ra: boolean) => !ra);
  };

  //// online stage
  // state
  const onlineStageOrigState =
    features &&
    features.hasOwnProperty('onlineStage') &&
    features.onlineStage.length > 0;
  const [onlineStageActive, setOnlineStageActive] =
    useState(onlineStageOrigState);

  const toggleOnlineStageActive = () => {
    setOnlineStageActive((osa: boolean) => !osa);
  };

  // !!! missing in API: activation of multiple features in one call

  return (
    <Stack>
      {(featureType === 'features' || featureType === 'mediaMng') && (
        <EventDetailsFormSection
          headlineTranslationString="DETAILS-FORM.SECTION.MEDIAMNG.HEADLINE"
          sublineTranslationString="DETAILS-FORM.SECTION.MEDIAMNG.SUBLINE"
          translationNs="event"
          Icon={StarsOutlinedIcon}
          // FormComponent={<></>}
          FormComponent={
            <EventDetailsFormLayoutFeaturesMedia
              featureState={mediaMngActive}
            />
          }
          mediaQueryMobile={mediaQueryMobile}
          active={mediaMngActive}
          activationHandler={toggleMediaMngActive}
        />
      )}

      {(featureType === 'features' || featureType === 'monet') && (
        <EventDetailsFormSection
          headlineTranslationString="DETAILS-FORM.SECTION.MONET.HEADLINE"
          sublineTranslationString="DETAILS-FORM.SECTION.MONET.SUBLINE"
          translationNs="event"
          Icon={MonetizationOnOutlinedIcon}
          FormComponent={
            <EventDetailsFormLayoutFeaturesMonet featureState={monetActive} />
          }
          mediaQueryMobile={mediaQueryMobile}
          active={monetActive}
          activationHandler={toggleMonetActive}
        />
      )}

      {(featureType === 'features' || featureType === 'register') && (
        <EventDetailsFormSection
          headlineTranslationString="DETAILS-FORM.SECTION.REGISTRATION.HEADLINE"
          sublineTranslationString="DETAILS-FORM.SECTION.REGISTRATION.SUBLINE"
          translationNs="event"
          Icon={HowToRegOutlinedIcon}
          FormComponent={
            <EventDetailsFormLayoutFeaturesRegistration
              featureState={registrationActive}
            />
          }
          mediaQueryMobile={mediaQueryMobile}
          active={registrationActive}
          activationHandler={toggleRegistrationActive}
        />
      )}

      {(featureType === 'features' || featureType === 'online') && (
        <EventDetailsFormSection
          headlineTranslationString="DETAILS-FORM.SECTION.ONLINE.HEADLINE"
          sublineTranslationString="DETAILS-FORM.SECTION.ONLINE.SUBLINE"
          translationNs="event"
          Icon={SmartDisplayOutlinedIcon}
          FormComponent={
            <EventDetailsFormLayoutFeaturesOnlineStage
              featureState={onlineStageActive}
            />
          }
          mediaQueryMobile={mediaQueryMobile}
          active={onlineStageActive}
          activationHandler={toggleOnlineStageActive}
        />
      )}
    </Stack>
  );
}
