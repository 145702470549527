import { FormControl, SxProps, Typography } from '@mui/material';
import { ColorPicker } from 'material-ui-color';
import { MuiColorInput } from 'mui-color-input';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { UpdateEventDataTypes } from '../../Event/Form/eventDetailsFormTypes';
import { useState } from 'react';

type _ColorPickerTypes<T extends FieldValues> = {
  fullWidth?: boolean;
  fieldName: Path<T>;
  fieldLabel: string;
  control: Control<T>;
  required?: boolean;
  error?: string;
  defaultValue?: any;
  setValue?: (name: keyof UpdateEventDataTypes, color: any) => void;
  variant?: 'outlined' | 'filled' | 'standard';
  type?: 'number' | 'password' | 'search';
  readOnly?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  sx?: SxProps;
};

export default function _ColorPicker<T extends FieldValues>(
  props: _ColorPickerTypes<T>,
) {
  const {
    fullWidth,
    fieldName,
    fieldLabel,
    control,
    required,
    variant,
    error,
    defaultValue = '#000',
    setValue,
    type,
    readOnly,
    disabled,
    autoComplete,
    sx,
  } = props;

  return (
    <FormControl fullWidth={fullWidth}>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <Typography>{fieldLabel}</Typography>

            <MuiColorInput
              {...field}
              error={Boolean(error)}
              helperText={error}
              variant={variant}
              format="hex"
              onChange={(color) => {
                setValue!(fieldName as keyof UpdateEventDataTypes, color);
              }}
              isAlphaHidden
            />
          </>
        )}
      ></Controller>
    </FormControl>
  );
}
