import { Box, Modal } from '@mui/material';
import useUserPermissions from '../Hooks/useUserPermissions';
import { Suspense, useEffect, useState } from 'react';
import EventSettings from './EventSettings';
import { ErLoadingIndicator } from 'eventrocket-commoncomponents';

export default function EventSettingsModal() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const events = window.fsEvents;

  //
  const [firstRun, setFirstRun] = useState(true);

  // toogle settings modal
  const [modalOpen, setModalOpen] = useState(false);

  // get the modal container
  const modalRoot = document.getElementById('modalRoot');

  const toggleEventSettingsModal = () => {
    setModalOpen(!modalOpen);
  };

  // permission settings
  const { hasPermission } = useUserPermissions();

  useEffect(() => {
    //
    if (firstRun) {
      //   toggleEventSettingsModal();
      //   toggleEventSettingsModal();
      setFirstRun(false);
    }

    const subSettingsMenuId = events?.subscribe('toggleEventMenu', function () {
      toggleEventSettingsModal();
    });

    return () => {
      if (subSettingsMenuId) {
        events?.unsubscribe(subSettingsMenuId);
      }
    };
  }, []);

  return (
    <>
      {hasPermission({ objPath: 'event.settingsMenu' }) && (
        <Modal
          open={modalOpen}
          onClose={toggleEventSettingsModal}
          container={modalRoot}
          // keepMounted={true}
          disablePortal={true}
        >
          <Suspense>
            <EventSettings handleCancel={toggleEventSettingsModal} />
          </Suspense>
        </Modal>
      )}
    </>
  );
}
