import * as yup from 'yup';
import i18n from 'i18next';

export const eventDetailsBasicValidationSchema = yup.object({
  title: yup
    .string()
    .required(() => i18n.t('event:LOGIN.ERROR.title_required')),
  description: yup
    .string()
    .max(256)
    .required(() => i18n.t('event:LOGIN.ERROR.description_required')),
  descriptionHtml: yup.string(),
  startdate: yup
    .date()
    .required(() => i18n.t('event:LOGIN.ERROR.startdate_required')),
  enddate: yup
    .date()
    .required(() => i18n.t('event:LOGIN.ERROR.enddate_required')),
  eventCode: yup
    .string()
    .required(() => i18n.t('event:LOGIN.ERROR.enddate_required')),
  type: yup.string().required(),
  // language: yup.string(),
  timezone: yup.string(),
  // logoFile: yup.array().of(yup.string()),
});

export const eventDetailsBasicDefaults = {
  title: 'Event',
  startdate: new Date(),
  enddate: new Date(),
  type: 'training',
  eventCode: 'EventCode',
  description: '',
  descriptionHtml: '',
  // location: '',
  // language: '',
  timezone: 'Europe/Berlin',
  // logoFile: [],
};
