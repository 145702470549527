import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { usePreviewContext } from './PreviewContext';
import { useEffect, useState } from 'react';
import useAuthStore from '../../store/authStore';
import getSessionTokenForPreviewApi from '../../api/user/getSessionTokenForPreviewApi';

export default function Preview() {
  // state
  const [previewSessionToken, setPreviewSessionToken] = useState(
    localStorage.getItem('bs_PLAYER_PREVIEW_TOKEN'),
  );

  const [firstRun, setFirstRun] = useState(true);
  // url params
  const { masterEventId } = useParams();

  // iframe src
  const [iframeSrc, setIframeSrc] = useState(
    process.env.REACT_APP_PLAYER_BASE_URL +
      '?eventId=' +
      masterEventId +
      '&preview=true',
  );

  // preview Context
  const { view } = usePreviewContext();

  useEffect(() => {
    if (view === 'announcement') {
      setIframeSrc(
        process.env.REACT_APP_PLAYER_BASE_URL +
          '?eventId=' +
          masterEventId +
          '&preview=true&pSessionToken=' +
          previewSessionToken,
      );
    } else {
      setIframeSrc(
        process.env.REACT_APP_PLAYER_BASE_URL +
          '/' +
          view +
          '?eventId=' +
          masterEventId +
          '&preview=true&pSessionToken=' +
          previewSessionToken,
      );
    }
  }, [view, previewSessionToken]);

  return (
    <>
      {/* preview iframe */}
      {previewSessionToken && previewSessionToken !== '' && (
        <Box sx={{ width: '100%', height: 'calc(100% - 64px)' }}>
          <iframe
            id="bs_PREVIEW_PLAYER"
            name="bs_PREVIEW_PLAYER"
            width="100%"
            height="100%"
            frameBorder="0"
            src={iframeSrc}
            allowFullScreen
          />
        </Box>
      )}
    </>
  );
}
