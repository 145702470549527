// import { Jodit } from 'jodit-react';
import { Jodit } from 'jodit-pro-react';
// import { Jodit } from 'jodit-pro-react';

// type DeepPartial<T> = T extends object
//   ? {
//       [P in keyof T]?: DeepPartial<T[P]>;
//     }
//   : T;
// interface EditorConfig extends DeepPartial<Jodit['options']> {}

interface EditorConfig extends Partial<Jodit['__options']> {}

export const htmlEditorDefaultConfig: any = {
  license: '',
  loadExternalConfig: false, // prevent error on loading
  readonly: false,
  placeholder: 'Start typings...',
  theme: 'dark',
  toolbarButtonSize: 'small',
  toolbarAdaptive: false,
  buttons: [
    'bold',
    'italic',
    'underline',
    'paragraph',
    '|',
    'left',
    'center',
    'right',
    'indent',
    'outdent',
    '|',
    'ul',
    'ol',
    '|',
    'undo',
    'redo',
    '|',
    {
      name: 'Variablen',
      tooltip: 'Variablen einfügen',
      list: { '{#firstname#}': 'Vorname', '{#lastname#}': 'Nachname' },
      exec: (editor: any, _: any, { control }: { control: any }) => {
        const value = control.args && control.args[0];
        if (!value) return false;
        editor.s.insertHTML(value);
      },
    },
    // {
    //   name: 'Vorname',
    //   tooltip: 'Vorname des Benutzers einfügen',
    //   exec: (editor: any) => {
    //     editor.s.insertHTML('{#firstname#}');
    //   },
    // },
    // {
    //   name: 'Nachname',
    //   tooltip: 'Nachname des Benutzers einfügen',
    //   exec: (editor: any) => {
    //     editor.s.insertHTML('{#lastname#}');
    //   },
    // },
  ],
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: 'Absatz',
        h1: 'Überschrift 1',
        h2: 'Überschrift 2',
        h3: 'Überschrift 3',
      }),
    },
  },
  // buttonsMD: '',
  // buttonsSM: '',
  // buttonsXS: '',
  // tabIndextabIndex: 1,
  // i18n: {
  //   de: {
  //     'Type something': 'Начните что-либо вводить',
  //   },
  // },
  // iframe: true,
  colorPickerDefaultTabcolorPickerDefaultTab: 'color',
  removeButtonsremoveButtons: [],
  disablePlugins: ['pasteStorage'],
  // history: { enable: true, maxHistoryLength: 20, timeout: 1 },
  addNewLine: false,
  // addNewLineDeltaShow: 0,
  // cleanHTML: {
  //   timeout: 1,
  //   replaceNBSP: true,
  //   fillEmptyParagraph: false,
  //   removeEmptyElements: true,
  //   // replaceOldTags: {'h1': 'h2'},
  //   useIframeSandbox: true,
  //   removeOnError: true,
  //   safeJavaScriptLink: true,
  //   // allowTags: {}
  // },
  cleanHTML: {
    removeEmptyElements: true,
    removeEmptyAttributes: true,
    fillEmptyParagraph: false,
    removeEmptyBlocks: true,
    removeTags: ['font', 'span', 'style'], // Entferne unerwünschte Tags
    removeAttributes: ['style', 'color', 'face', 'class'], // Entferne unerwünschte Attribute
  },
  paste: {
    useDefaultPasteBehaviour: false,
    cleanOnPaste: true,
    cleanAttrs: ['style', 'color', 'font', 'face', 'class'],
    cleanTags: ['font', 'span', 'style'],
    rules: {
      tags: {
        p: {
          rename_tag: 'p',
        },
        b: {
          rename_tag: 'strong',
        },
        i: {
          rename_tag: 'em',
        },
        u: {
          rename_tag: 'u',
        },
        ul: {
          rename_tag: 'ul',
        },
        ol: {
          rename_tag: 'ol',
        },
        li: {
          rename_tag: 'li',
        },
        div: {
          rename_tag: 'div',
        },
        h1: {
          rename_tag: 'h1',
        },
        h2: {
          rename_tag: 'h2',
        },
        h3: {
          rename_tag: 'h3',
        },
        h4: {
          rename_tag: 'h4',
        },
        h5: {
          rename_tag: 'h5',
        },
        h6: {
          rename_tag: 'h6',
        },
        blockquote: {
          rename_tag: 'blockquote',
        },
        left: {
          rename_tag: 'left',
        },
        center: {
          rename_tag: 'center',
        },
        right: {
          rename_tag: 'right',
        },
        justify: {
          rename_tag: 'justify',
        },
      },
    },
  },
  globalFullSize: true,
  askBeforePasteFromWord: true,
  processPasteFromWord: true,
  defaultActionOnPasteFromWord: 'insert_clear_html',
  askBeforePasteHTML: true,
  processPasteHTML: true,
  nl2brInPlainText: true,
  beautifyHTML: false,
  sourceEditor: 'area',
  toolbarSticky: true,
  specialCharacters: [],
  usePopupForSpecialCharacters: true,
  // colors: ['#ff0000', '#00ff00', '#0000ff'], // colors for text & bg color
  limitWords: false,
  limitChars: false,
  limitHTML: false,
};
