import { useForm, useWatch } from 'react-hook-form';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
} from '@mui/material';
import { ErSelect, ErSwitch, ErTextField } from 'eventrocket-commoncomponents';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import { yupResolver } from '@hookform/resolvers/yup';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  eventDetailsCustomizationValidationSchema,
  eventDetailsCustomizationDefaults,
} from './eventDetailsCustomizationValidationSchema';
import {
  onlinePlayerDateFormat,
  onlinePlayerDateTime,
  onlinePlayerDividerActors,
  onlinePlayerDividerTitle,
  onlinePlayerTextSizeOptions,
} from '../../../util/onlineStageSettingsOptions';

export default function EventDetailsFormLayoutOnlineStageDisplay() {
  // props

  // translation
  const { t } = useTranslation('event');

  // event details context
  const { customizationData, updateCustomizationData } =
    useEventDetailsFormData();

  console.log('updateCustomizationData-4', customizationData);

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsCustomizationValidationSchema),
    defaultValues: {
      ...eventDetailsCustomizationDefaults,
      ...customizationData?.data,
    },
  });

  const watch = useWatch({ control });

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context

    if (!firstRun) {
      updateCustomizationData(getValues());
    } else {
      setFirstRun(false);
    }
  }, [watch]);

  return (
    <>
      <Stack sx={{ mt: 2 }}>
        <ErSelect
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SELECT_textSize',
          )}
          fieldName="textSize"
          control={control}
          size="small"
          variant="outlined"
          menuItems={onlinePlayerTextSizeOptions}
          sx={{ mb: 2 }}
        />

        <ErSelect
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SELECT_dividerActors',
          )}
          fieldName="dividerActors"
          control={control}
          size="small"
          variant="outlined"
          menuItems={onlinePlayerDividerActors}
          sx={{ mb: 2 }}
        />

        <ErSelect
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SELECT_dividerTitle',
          )}
          fieldName="dividerTitle"
          control={control}
          size="small"
          variant="outlined"
          menuItems={onlinePlayerDividerTitle}
          sx={{ mb: 2 }}
        />

        <ErSelect
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SELECT_dateFormat',
          )}
          fieldName="dateFormat"
          control={control}
          size="small"
          variant="outlined"
          menuItems={onlinePlayerDateFormat}
          sx={{ mb: 2 }}
        />

        <ErSelect
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SELECT_timeFormat',
          )}
          fieldName="timeFormat"
          control={control}
          size="small"
          variant="outlined"
          menuItems={onlinePlayerDateTime}
          sx={{ mb: 2 }}
        />

        <ErTextField
          fieldLabel={t(
            'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.FIELD_agendaHeadline',
          )}
          fieldName="agendaHeadline"
          size="small"
          variant="outlined"
          control={control}
          sx={{ mb: 2 }}
        />

        <Stack direction="row" spacing={2}>
          <ErSwitch
            control={control}
            fieldLabel={t(
              'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.CHECKBOX_dateInTitle',
            )}
            fieldName="dateInTitle"
            size="small"
            labelPos="end"
            sx={{ mb: 2 }}
          />
          <ErSwitch
            control={control}
            fieldLabel={t(
              'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.CHECKBOX_locationInTitle',
            )}
            fieldName="locationInTitle"
            size="small"
            labelPos="end"
            sx={{ mb: 2 }}
          />
        </Stack>

        <Stack direction="row">
          <ErSwitch
            fieldLabel={t(
              'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.CHECKBOX_slotTitleInSecondaryColor',
            )}
            fieldName="slotTitleInSecondaryColor"
            control={control}
            size="small"
            labelPos="end"
            sx={{ mb: 2 }}
          />
        </Stack>
      </Stack>
    </>
  );
}
