import { useForm, useWatch } from 'react-hook-form';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
} from '@mui/material';
import {
  ErHtmlEditor,
  ErSwitch,
  ErTextField,
} from 'eventrocket-commoncomponents';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import { yupResolver } from '@hookform/resolvers/yup';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  eventDetailsMarketingValidationSchema,
  eventDetailsMarketingDefaults,
} from './eventDetailsMarketingValidationSchema';

import { htmlEditorDefaultConfig } from '../../../util/htmlEditor/htmlEditorDefaultConfig';

export default function EventDetailsFormLayoutMarketingText() {
  // props

  // translation
  const { t } = useTranslation('event');

  // event details context
  const { marketingData, updateMarketingData } = useEventDetailsFormData();

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsMarketingValidationSchema),
    defaultValues: {
      ...eventDetailsMarketingDefaults,
      ...marketingData?.data,
    },
  });

  const watch = useWatch({ control });

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context

    if (!firstRun) {
      updateMarketingData(getValues());
    } else {
      setFirstRun(false);
    }
  }, [watch]);

  return (
    <>
      <Stack sx={{ mt: 2 }}>
        <Stack direction="row">
          <ErSwitch
            control={control}
            fieldName="showEventText"
            fieldLabel={t(
              'DETAILS-FORM.SECTION.MARKETINGTEXT.SWITCH_showEventText',
            )}
            labelPos="end"
          />

          <ErSwitch
            control={control}
            fieldName="showProgram"
            fieldLabel={t(
              'DETAILS-FORM.SECTION.MARKETINGTEXT.SWITCH_showProgram',
            )}
            labelPos="end"
          />
        </Stack>

        <Stack direction="row" sx={{ mb: 2 }}>
          <ErSwitch
            control={control}
            fieldName="showCountDown"
            fieldLabel={t(
              'DETAILS-FORM.SECTION.MARKETINGTEXT.SWITCH_showCountDown',
            )}
            labelPos="end"
          />

          <ErSwitch
            control={control}
            fieldName="showAnnouncementText"
            fieldLabel={t(
              'DETAILS-FORM.SECTION.MARKETINGTEXT.SWITCH_showAnnouncementText',
            )}
            labelPos="end"
          />
        </Stack>

        <ErHtmlEditor
          control={control}
          editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
          fieldLabel={t(
            'DETAILS-FORM.SECTION.MARKETINGTEXT.FIELD_announcementHtml',
          )}
          fieldName="announcementHtml"
          sx={{ mb: 2 }}
          error={
            errors.announcementHtml &&
            getErrorMessage(errors, 'announcementHtml')
          }
          onChangeCallback={() =>
            !marketingData?.isDirty && updateMarketingData({}, true)
          }
          language="de"
        />

        {/* <ErHtmlEditor
          control={control}
          editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
          fieldLabel={t('DETAILS-FORM.SECTION.MARKETINGTEXT.FIELD_agendaHtml')}
          fieldName="agendaHtml"
          sx={{ mb: 2 }}
          error={errors.agendaHtml && getErrorMessage(errors, 'agendaHtml')}
          onChangeCallback={() =>
            !marketingData?.isDirty && updateMarketingData({}, true)
          }
          language="de"
        /> */}
      </Stack>
    </>
  );
}
