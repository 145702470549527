import { useForm, useWatch } from 'react-hook-form';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import { Box, Stack } from '@mui/material';
import { ErTextField } from 'eventrocket-commoncomponents';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEffect, useMemo, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  eventDetailsMetaValidationSchema,
  eventDetailsMetaDefaults,
} from './eventDetailsMetaValidationSchema';
import { useTranslation } from 'react-i18next';

export default function EventDetailsFormLayoutMeta() {
  // props

  // event details context
  const { metaData, updateMetaData } = useEventDetailsFormData();

  // translation
  const { t } = useTranslation('event');

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsMetaValidationSchema),
    defaultValues: { ...eventDetailsMetaDefaults, ...metaData?.data },
  });

  const watch = useWatch({ control });

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context

    if (!firstRun) {
      updateMetaData(getValues());
    } else {
      setFirstRun(false);
    }
  }, [watch]);

  return (
    <>
      {/* <Box height="1280px" width="100%" bgcolor="red"></Box> */}
      <Stack sx={{ mt: 2 }}>
        <ErTextField
          size="small"
          control={control}
          fieldLabel={t(
            'DETAILS-FORM.SECTION.META.FIELD_organization',
            '_DETAILS-FORM.SECTION.META.FIELD_organization',
          )}
          fieldName="organization"
          sx={{ mb: 2 }}
          error={errors.organization && getErrorMessage(errors, 'organization')}
        />

        <ErTextField
          size="small"
          control={control}
          fieldLabel={t(
            'DETAILS-FORM.SECTION.META.FIELD_contact.person',
            '_DETAILS-FORM.SECTION.META.FIELD_contact.person',
          )}
          fieldName="contact.person"
          sx={{ mb: 2 }}
          error={
            errors['contact.person'] &&
            getErrorMessage(errors, 'contact.person')
          }
        />

        <ErTextField
          size="small"
          control={control}
          fieldLabel={t(
            'DETAILS-FORM.SECTION.META.FIELD_contact.email',
            '_DETAILS-FORM.SECTION.META.FIELD_contact.email',
          )}
          fieldName="contact.email"
          sx={{ mb: 2 }}
          error={
            errors['contact.email'] && getErrorMessage(errors, 'contact.email')
          }
        />

        <ErTextField
          size="small"
          control={control}
          fieldLabel={t(
            'DETAILS-FORM.SECTION.META.FIELD_contact.phone',
            '_DETAILS-FORM.SECTION.META.FIELD_contact.phone',
          )}
          fieldName="contact.phone"
          sx={{ mb: 2 }}
          error={
            errors['contact.phone'] && getErrorMessage(errors, 'contact.phone')
          }
        />

        <ErTextField
          size="small"
          control={control}
          fieldLabel={t(
            'DETAILS-FORM.SECTION.META.FIELD_website',
            '_DETAILS-FORM.SECTION.META.FIELD_website',
          )}
          fieldName="website"
          sx={{ mb: 2 }}
          error={errors.website && getErrorMessage(errors, 'website')}
        />
      </Stack>
    </>
  );
}
