import Parse from 'parse';
import { UpdateSlotDataTypes } from '../../Components/Slots/Form/slotDetailsFormTypes';
import useAuthStore from '../../store/authStore';

// !!! typings
export default async function setNewSlotApi(
  data: any,
  eventId: string,
  addAtIndex?: number | undefined,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  // remove parse objects before sending
  delete data.actors;
  delete data.media;

  // set default
  // if (!data.title || data.title === '') {
  //   data.title = 'Neuer Slot';
  // }

  data.eventId = eventId;

  return Parse.Cloud.run(
    'setNewSlot',
    { ...data, addAtIndex: addAtIndex },
    { sessionToken: sessionToken },
  )
    .then((res) => {
      //// data conversion
      // map the result to a regular obj
      const slotMapped = { ...res.attributes };

      // get the id & the sessionId
      slotMapped.sessionId = slotMapped.session.id;
      slotMapped.id = res.id;

      // delete the session object
      delete slotMapped.session;

      return slotMapped;
    })
    .catch((e) => {
      throw new Error(e);
    });
}
