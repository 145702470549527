import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import useLiveStore from '../../store/liveStore';
import getLiveDataApi from '../../api/player/getLiveDataApi';

// Layer II Data Handling
export function useLiveDataQuery(eventId: string) {
  const queryClient = useQueryClient();
  return useQuery(
    {
      queryKey: ['event', 'live', eventId],
      queryFn: () => getLiveDataApi(eventId),
    },
    queryClient,
  );
}

// Layer III Data Handling
export default function useLiveDataQueryHook(eventId: string) {
  // event query
  const { data, error, isLoading, isSuccess, isError, status } =
    useLiveDataQuery(eventId);
  // event store
  const { setDataSessions, setDataSlots, setStatus } = useLiveStore();

  useEffect(() => {
    // update data
    if (data) {
      setDataSessions(data.sceneData.sessions);
      setDataSlots(data.sceneData.slots);
    }
  }, [data]);

  useEffect(() => {
    // update state
    setStatus(status);
  }, [status]);

  return { data, isLoading, isSuccess };
}
