import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
export type authTypes = {
  user: Parse.User | undefined;
};

type State = {
  [key: string]: any;
  // Ein generischer Zustand, der beliebige Schlüssel/Wert-Paare akzeptiert
  // Sie können dies weiter einschränken, wenn Sie spezifische Eigenschaften haben
};

// init empty store
const useAuthStore = create<State>(() => ({ user: {} }));

// populate store
export function populateAuthStore(data: authTypes) {
  useAuthStore.setState(data);
}

// export hook
export default useAuthStore;
