import * as yup from 'yup';
import i18n from 'i18next';

export const eventDetailsMetaValidationSchema = yup.object({
  organization: yup.string(),
  contact: yup
    .object()
    .shape({ person: yup.string(), email: yup.string(), phone: yup.string() }),
  website: yup.string(),
});

export const eventDetailsMetaDefaults = {
  organization: '',
  contact: { person: '', email: '', phone: '' },
  website: '',
};
