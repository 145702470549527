import React from 'react';
import {
    Box,
    FormControl,
    SxProps,
    FormHelperText,
    useTheme,
    FormLabel,
  } from '@mui/material';
  // import JoditEditor, { Jodit } from 'jodit-react';
  import JoditEditor, { Jodit } from 'jodit-pro-react';
  import { IJoditEditorProps } from 'jodit-pro-react';
  
  import { useMemo, useRef } from 'react';
  import {
    Control,
    Controller,
    FieldValues,
    Path,
    SetFieldValue,
    UseFormGetValues,
  } from 'react-hook-form';
  import { htmlTextDefaultConfig } from './ErHtmlEditor.defaultOptions';
  
//   import './themes/bsDark.css';
  import { HtmlTextTypes } from './ErHtmlEditor.types';   
  

export default function ErHtmlEditor<T extends FieldValues>(
    props: HtmlTextTypes<T>,
  ) {
    const {
      fullWidth,
      control,
      onChangeCallback,
      // setValue,
      // getValues,
      fieldName,
      fieldLabel,
      defaultValue = '',
      disabled = false,
      editorConfig,
      error,
      required,
      sx,
      language='de',
    } = props;
  
    const editor = useRef(null);
    const theme = useTheme();
  
    const config = useMemo(() => {
      return {
        // ...Jodit.defaultOptions,
        ...htmlTextDefaultConfig,
        ...editorConfig,
        disabled: disabled,
        language: language,
      };
    }, []);
  
    return (
      <>
        <FormControl
          fullWidth={fullWidth}
          error={Boolean(error)}
          required={required}
          sx={sx}
        >
          <FormLabel sx={{ mb: 1 }}>{fieldLabel}</FormLabel>
          <Controller
            name={fieldName}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value } }) => (
              <Box sx={{ position: 'relative' }}>
                <JoditEditor
                  ref={editor}
                  value={value}
                  config={config}
                  onBlur={(newContent) => {
                    onChange(newContent); // Update the value in the form
                    onBlur(); // Mark the field as touched
                    console.log('#!!#!-htmlEditorBlur', newContent);
                    
                  }}
                  onChange={(newContent) => {
                    onChangeCallback && onChangeCallback();
                    // onChange(newContent);
                    // onBlur();
                  }}
                />
              </Box>
            )}
          />
          {Boolean(error) && (
            <FormHelperText sx={{ color: theme.palette.error.main }}>
              {error}
            </FormHelperText>
          )}
        </FormControl>
      </>
    );
  }