import { Suspense, lazy, useEffect, useState } from 'react';
import { useFeedback } from './FeedbackContext';
import { Modal } from '@mui/material';

export default function FeedbackDisplayController() {
  // error context
  const feedbackContext = useFeedback();

  // lazy loads
  const ErrorUi = lazy(() => import('./FeedbackDisplayError'));
  const ConfirmUi = lazy(() => import('./FeedbackDisplayConfirm'));
  const LoadingUi = lazy(() => import('./FeedbackDisplayLoading'));

  const { feedback, setFeedback } = feedbackContext;

  // state
  const [firstRun, setFirstRun] = useState(true);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [ui, setUi] = useState(<></>);

  // handler
  const closeHandler = () => {
    // don't close on click if we have a loading indicator
    if (feedback?.type !== 'loading') {
      // close modal
      setFeedback(null);

      // call cancel callback if available
      if (feedback?.callbackCancel) {
        feedback.callbackCancel();
      }
    }
  };

  const closeForConfirmHandler = () => {
    // don't close on click if we have a loading indicator
    if (feedback?.type !== 'loading') {
      // close modal
      setFeedback(null);
    }
  };

  const cancelHandler = () => {
    // don't close on click if we have a loading indicator
    if (feedback?.type !== 'loading') {
      // close modal
      setFeedback(null);

      // call cancel callback if available
      if (feedback?.callbackCancel) {
        feedback.callbackCancel();
      }
    }
  };

  // on error change
  useEffect(() => {
    if (!firstRun) {
      let autoClose = false;

      // set autoclose
      switch (feedback?.autoclose) {
        case false:
          break;
        default:
          autoClose = true;
      }

      // switch the UI
      switch (feedback?.type) {
        case 'error':
        case 'success':
        case 'warning':
          setUi(
            <ErrorUi
              headline=""
              subline=""
              type={feedback?.type}
              errorCode={feedback?.errorCode}
              message={feedback?.message}
              closeHandler={closeHandler}
              autoClose={autoClose}
            />,
          );
          break;
        case 'confirmAction':
          setUi(
            <ConfirmUi
              headline={feedback?.headline}
              message={feedback?.message}
              closeHandler={closeHandler}
              closeForConfirmHandler={closeForConfirmHandler}
              callback={feedback?.callback}
              cancelHandler={cancelHandler}
            />,
          );
          break;
        case 'loading':
          setUi(<LoadingUi headline={feedback?.headline} subline="" />);
          break;
        case null:
          break;
        default:
          <></>;
      }

      // setOpenFeedbackDisplay(fb => !fb);
      if (feedback === null) {
        setOpenFeedbackModal(false);
      } else {
        setOpenFeedbackModal(true);
      }
    }

    setFirstRun(false);
  }, [feedback]);

  return (
    <>
      <Modal
        open={openFeedbackModal}
        onClose={closeHandler}
        keepMounted
        sx={{ zIndex: 1400 }}
      >
        <Suspense>{ui}</Suspense>
      </Modal>
    </>
  );
}
