import { SvgIconComponent } from '@mui/icons-material';
import { Stack, Switch, Typography } from '@mui/material';
import { ReactNode } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

type EventDetailsFormSectionMenuPropsTypes = {
  // expandedId: string;
  // targetId: string;
  headlineTranslationString: string;
  sublineTranslationString: string;
  translationNs?: string;
  Icon: SvgIconComponent;
  //   FormComponent: ReactNode;
  //   mediaQueryMobile: boolean;
  //   keepOpen?: boolean;
  //   status?: string;
  //   active?: boolean;
  clickHandler?: () => void;
  PaymentIcon?: ReactNode;
};

export default function EventDetailsFormSectionMenu(
  props: EventDetailsFormSectionMenuPropsTypes,
) {
  // props
  const {
    headlineTranslationString,
    sublineTranslationString,
    Icon,
    clickHandler,
    PaymentIcon,
  } = props;

  // translation
  const { t } = useTranslation('event');

  return (
    <>
      <Stack
        sx={{
          width: '100%',
          cursor: 'pointer',
          p: 1,
          //   '&:not(:last-child)': { borderBottom: '1px solid red' },
        }}
        onClick={clickHandler}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Icon />
            <Stack>
              <Typography variant="subtitle1">
                {t(headlineTranslationString, '_' + headlineTranslationString)}
              </Typography>
              <Typography variant="caption">
                {t(sublineTranslationString, '_' + sublineTranslationString)}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            {PaymentIcon && PaymentIcon}
            <ArrowForwardIosOutlinedIcon />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
